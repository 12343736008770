import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import config from "../coreFIles/config";
import ReactDatatable from "@ashvin27/react-datatable";
import { getnotificationAction, addnotificationAction, getUsersListAction, getItemListAction, getUsersEmailListAction, getCategoryListAction } from "../Action/action";
import moment from "moment";
import { toast, Toaster } from "react-hot-toast";
import { Dialog } from "@blueprintjs/core";
import AsyncSelect from "react-select/async";
import "@blueprintjs/core/lib/css/blueprint.css";
import Select from 'react-select';
import debounce from 'lodash/debounce';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

const Notification = () => {
  const [getnotification, setnotification] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [form, setform] = useState({
    users: [],
    usersToExclude: [],
    itemId: null,
    categoryId: null,
    title: "",
    message: "",
    sendToAll: false,
    sendToUnregistred: false,
    excludeBuyers: false,
    includeBuyers: false,
    sendToInterestedUsers: false,
  });
  const [validatioError, setvalidatioError] = useState({});

  const [itemdetails, setitemdetails] = useState([]);
  const [isDialogOpen, setisDialogOpen] = useState(false);
  const [saving, setsaving] = useState(false);
  const [show] = useState(true);
  const [emailed, setEmailed] = useState([]);
  const [categories, setCategories] = useState([]);
  const [itemList, setItemList] = useState([]);

  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [isError, setIsError] = useState(false);

  // Funzione debounced per la ricerca
  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      if (searchQuery) {
        const filters = JSON.stringify([
          {
            id: "name",
            value: searchQuery
          }
        ]);
        getItems(filters);
      } else {
        getItems();
      }
    }, 500),
    []
  );

  const getItems = async (filters = "") => {
    const limit = filters ? 10000 : 20;
    let res = await getItemListAction(0, limit, filters);
    if (res.success) {
      let selectList = res.data.items.map((el) => ({
        value: el.id,
        label: el.name,
        image: config.imageUrl + el.image,
      }));
      setItemList(selectList);
    }
  };


  // Modifica l'inputHandler per gestire la select
  const handleItemChange = (selectedOption) => {
    setform({ 
      ...form, 
      itemId: selectedOption ? selectedOption.value : 0 
    });
  };

  useEffect(() => {
    getnotificationlist();
    getusersdetails();
    getItems();
    getcategories();
  }, []);

  const getcategories = async () => {
    let res = await getCategoryListAction();
    if (res) {
      setCategories(res.data);
      console.log(res.data);
    }
  };
  const inputHandler = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setform({ ...form, [name]: value });
  };

  const handleSelectAllClick = () => {
    setform((prev) => ({ ...prev, sendToAll: !prev.sendToAll, users: [], usersToExclude: [] }));
  };
  const handleSelectUnregistred = () => {
    setform((prev) => ({ ...prev, sendToUnregistred: !prev.sendToUnregistred }));
  };

  const handleSelectExcludeBuyers = () => {
    setform((prev) => ({ ...prev, excludeBuyers: !prev.excludeBuyers, includeBuyers: false }));
  };

  const handleSelectIncludeBuyers = () => {
    setform((prev) => ({ ...prev, includeBuyers: !prev.includeBuyers, excludeBuyers: false }));
  };

  function validate() {
    let titleError = "";
    let messageError = "";

    if (form.title === "") {
      titleError = "Title is required.";
    }
    if (form.message === "") {
      messageError = "Notification message is required.";
    }
    if (titleError || messageError) {
      setvalidatioError({
        titleError,
        messageError,
      });
      return false;
    } else {
      return true;
    }
  }

  const getusersdetails = async () => {
    let res1 = await getUsersEmailListAction();

    if (res1) {
      setEmailed(res1?.data);
    }
  };


  const getnotificationlist = async () => {
    if (!getnotification.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {
      let filters = JSON.stringify(columnFilters ?? []);
      let res = await getnotificationAction(
        pagination.pageIndex,
        pagination.pageSize,
        filters
      );

      if (res.success) {
        setnotification(res.data.notifications);
        setRowCount(res.data.totalItems);
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  useEffect(() => {
    getnotificationlist();
  }, [columnFilters, pagination.pageIndex, pagination.pageSize, sorting]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Sno.",
        enableSorting: false,
        size: 50,
      },
      {
        accessorKey: "user.email",
        header: "Email",
        enableSorting: false,
        Cell: ({ row }) => {
          return row.original.user.email ? (
            <a 
              href={`${config.baseUrl}userDetails?id=${row.original.user.id}`}
              style={{ 
                color: '#007bff',
                textDecoration: 'none'
              }}
              onMouseOver={(e) => e.target.style.textDecoration = 'underline'}
              onMouseOut={(e) => e.target.style.textDecoration = 'none'}
            >
              {row.original.user.email}
            </a>
          ) : "-";
        }
      },
      {
        accessorKey: "title",
        header: "Title",
        enableSorting: false,
      },
      {
        accessorKey: "notifcation_message", 
        header: "Message",
        enableSorting: false,
        Cell: ({ row }) => (
          <textarea 
            rows={3} 
            className="form-control" 
            value={row.original.notifcation_message} 
            readOnly 
          />
        )
      },
      {
        accessorKey: "datetime",
        header: "Date",
        enableColumnFilter: false, // Disabilita il filtro per questa colonna
        enableSorting: false, // Disabilita il sorting per questa colonna
        Cell: ({ row }) => moment(row.original.datetime).format("YYYY-MM-DD")
      }
    ],
    []
  );

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark",
        },
      }),
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: getnotification,
    enableColumnActions: true,
    enableColumnFilters: true,
    enablePagination: true,
    enableSorting: true,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableGlobalFilter: false,
    initialState: { showColumnFilters: true },
    muiToolbarAlertBannerProps: isError
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,

    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid #ffffff1f",
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
  });

  const configForTable = {
    page_size: pagination.pageSize,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    pagination: "advance",
    page_number: pagination.pageIndex,
    total_rows: rowCount,
    onPageChange: (pageIndex) => {
      setPagination(prev => ({
        ...prev,
        pageIndex: pageIndex - 1 // Convert 1-based to 0-based
      }));
    },
    onPageSizeChange: (pageSize) => {
      setPagination(prev => ({
        ...prev,
        pageSize,
        pageIndex: 0 // Reset to first page when changing page size
      }));
    },
    onFilterChange: (filters) => {
      setColumnFilters(filters);
      setPagination(prev => ({
        ...prev,
        pageIndex: 0
      }));
    }
  };

  const addNotificationlist = async (e) => {
    e.preventDefault();
    const isValid = validate();

    if (!isValid) {
      return;
    }
    console.log(form);

    setsaving(true);

    let res = await addnotificationAction(form);

    if (res.success) {
      toast.success(res.msg);
      setTimeout(() => {
        window.location.href = `${config.baseUrl}notification`;
      }, 2000);
      getnotificationlist();
    } else {
      toast.error(res.msg);
      setsaving(false);
    }
  };

  return (
    <>
      <div className="wrapper">
        {/* <div id="loader"></div> */}
        <Dialog
          title="Please Wait..."
          style={{
            color: "#590ba2",
            textAlign: "center",
          }}
          isOpen={isDialogOpen}
          isCloseButtonShown={false}
        >
          <div className="text-center pl-3 pr-3">
            <br />

            <p style={{ color: "red" }}>Please do not refresh page or close tab.</p>
            <div>
              <div className="spinner-border"></div>
            </div>
          </div>
        </Dialog>
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Notification List</h4>
                      {/* <a
                        href={`${config.baseUrl}itemAdd `}
                        className="btn btn-sm btn-primary add_btn"
                      >
                        Add New
                      </a> */}
                      <button
                        type="button"
                        //   onClick={() => editPartner(item)}
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal1"
                        //   name={item.id}
                        //   value={item.id}
                      >
                        Send Notification
                      </button>
                    </div>
                    <div className="box-body">
                      <ThemeProvider theme={tableTheme}>
                        <MaterialReactTable table={table} />
                      </ThemeProvider>
                    </div>
                  </div>
                </div>
              </div>

              {/*Add Modal */}
              <div
                className="modal fade"
                id="exampleModal1"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{
                  display: show === true ? "none" : "block",
                }}
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Send Notification
                      </h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <form onSubmit={addNotificationlist}>
                      <div className="modal-body">
                        <div className="container">
                          <div className="mb-3">
                            <label className="form-label">Item List</label>
                            <Select
                              name="itemId"
                              value={itemList.find(option => option.value === form.itemId)}
                              options={itemList}
                              formatOptionLabel={(item) => (
                                <div>
                                  <img 
                                    width="20px" 
                                    height="20px" 
                                    src={item.image} 
                                    alt="" 
                                  />
                                  <span>{item.label}</span>
                                </div>
                              )}
                              onChange={handleItemChange}
                              onInputChange={(value) => {
                                debouncedSearch(value);
                              }}
                              filterOption={null}
                              isSearchable={true}
                              isClearable={true}
                              placeholder="-Select item-"
                              styles={{ 
                                container: (base) => ({ 
                                  ...base, 
                                  marginBottom: "20px" 
                                }) 
                              }}
                            />
                            {form.itemId && (
                              <div className="mb-3">
                                <input 
                                  type="checkbox" 
                                  className="me-2" 
                                  checked={form.sendToInterestedUsers} 
                                  onChange={() => setform(prev => ({ 
                                    ...prev, 
                                    sendToInterestedUsers: !prev.sendToInterestedUsers 
                                  }))} 
                                />
                                Send to users interested in this item (favorites and alerts)
                              </div>
                            )}
                          </div>
                          <div className="mb-3">
                            <label className="form-label mt-3">Users who purchased in category</label>
                            <select className="form-control" name="categoryId" value={form.categoryId} onChange={inputHandler}>
                              <option value="">-Select category-</option>
                              {categories.map((category) => (
                                <option key={category.id} value={category.id}>
                                  {category.category_name}
                                </option>
                              ))}
                            </select>
                          </div>
                          {!form.sendToAll && (
                            <div className="mb-3 ">
                              <label className="form-label">Users List</label>
                              {/* <div>{selectedOptionsLength}</div> */}
                              <AsyncSelect
                                cacheOptions
                                isMulti
                                loadOptions={async (value) => {
                                  if (value.length > 4) {
                                    let data = emailed
                                      .filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
                                      .map((item) => ({ value: item.id, label: item.name }));
                                    return data;
                                  } else {
                                    return [];
                                  }
                                }}
                                onChange={(data) => {
                                  setform({ ...form, users: data.map((item) => item.value) });
                                }}
                                defaultOptions
                              />
                            </div>
                          )}
                          {<div className="mb-3">
                            <input type="checkbox" className="me-2" checked={form.sendToAll} onChange={handleSelectAllClick} />
                            Select all registred users
                          </div>}
                          {form.sendToAll && (
                            <div className="mb-3 ">
                              <label className="form-label">Users to exclude</label>
                              {/* <div>{selectedOptionsLength}</div> */}
                              <AsyncSelect
                                cacheOptions
                                isMulti
                                loadOptions={async (value) => {
                                  if (value.length > 4) {
                                    let data = emailed
                                      .filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
                                      .map((item) => ({ value: item.id, label: item.name }));
                                    return data;
                                  } else {
                                    return [];
                                  }
                                }}
                                onChange={(data) => {
                                  setform({ ...form, usersToExclude: data.map((item) => item.value) });
                                }}
                                defaultOptions
                              />
                            </div>
                          )}
                          <div className="mb-3">
                            <input type="checkbox" className="me-2" checked={form.sendToUnregistred} onChange={handleSelectUnregistred} />
                            Send to unregistred users
                          </div>

                          {form.sendToAll && !form.categoryId && (
                            <>
                              <div className="mb-3">
                                <input type="checkbox" className="me-2" checked={form.excludeBuyers} onChange={handleSelectExcludeBuyers} />
                                Exclude buyers
                              </div>
                              <div className="mb-3">
                                <input type="checkbox" className="me-2" checked={form.includeBuyers} onChange={handleSelectIncludeBuyers} />
                                Only buyers
                              </div>
                            </>
                          )}
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              Title
                            </label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter title" onChange={inputHandler} name="title" />
                            <span className="validationErr">{validatioError.titleError}</span>
                          </div>
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              Enter Message
                            </label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter Message" onChange={inputHandler} name="message" />
                            <span className="validationErr">{validatioError.messageError}</span>
                          </div>
                          <div className="modal-footer mt-20">
                            {!saving ? (
                              <button type="submit" className="btn btn-primary">
                                Send
                              </button>
                            ) : (
                              <button type="submit" className="btn btn-primary" disabled>
                                Please wait <i className="fa fa-spinner fa-spin"></i>
                              </button>
                            )}

                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/*Add Modal Ends */}
            </section>
            {/* /.content */}
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Notification;
