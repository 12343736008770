import React, { useEffect, useState, useCallback } from 'react'
import config from '../coreFIles/config';
import { Toaster } from 'react-hot-toast';
import Header from '../directives/header';
import Footer from '../directives/footer';
import Sidebar from '../directives/sidebar';
import ReactDatatable from '@ashvin27/react-datatable';
import { getReferrals, getUsersLiquidity, setUsersLiquidity, setReferralBonus } from '../Action/action';
import Swal from 'sweetalert2';

const Referrals = () => {
    const [usersList, setusersList] = useState({});

    //Liquidity Modal
    const [item, setItem] = useState({});
    const [error, setError] = useState(false);
    const [loader, setLoader] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [saving, setsaving] = useState(false);
    const [lqiAmt, setLqiAmt] = useState(0);
    const [validatioError, setvalidatioError] = useState({});

    const getUsersList = useCallback(async () => {
        setLoader(true);
        let res = await getReferrals();
        if (res.success) {
            setLoader(false);
            setusersList(res.data);
        } else {
            setLoader(false);
        }
    }, []);

    useEffect(() => {
        getUsersList();
    }, [getUsersList]);

    const columns = [
        {
            key: "Sno.",
            text: "Sno.",
            cell: (row, index) => index + 1
        },
        {
            key: "id",
            text: "Id Transaction",
            cell: (item) => {
                return item.id;
            }
        },
        {
            key: "from_user",
            text: "From User",
            Hide: true,
            cell: (item) => {
                return (
                    <>
                        <strong>Name:</strong> {item.referrer_name} <br />
                        <strong>Email:</strong> {item.referrer_email} <br />
                    </>
                )
            }
        },
        {
            key: "to_user",
            text: "To User",
            Hide: true,
            cell: (item) => {
                return (
                    <>
                        <strong>Name:</strong> {item.referred_name} <br />
                        <strong>Email:</strong> {item.referred_email} <br />
                    </>
                )
            },
            sortable: false,
        },
        {
            key: "status",
            text: "Referral Status",
            cell: (item) => {
                return (
                    <>
                        {item.status === "waitingFirstPurchase" && "Waiting First Purchase"}
                        {item.status === "waitingAdminBonus" && "Waiting Admin Bonus"}
                        {item.status === "bonusReceived" && "Bonus Received"}
                    </>
                );
            },
        },
        {
            key: "set_bonus",
            text: "Set Bonus Amount",
            cell: (item) => {
                return (
                    <>
                        {item.status === "bonusReceived" ?
                            <p className="text-center">Bonus already set</p>
                            :
                            <button
                                onClick={() => handleLiquidityModal(item)}
                                type="button"
                                className="btn btn-sm btn-primary mx-auto"
                                data-bs-toggle="modal"
                                data-bs-target="#liquidityModal"
                                disabled={!item.status || item.status === "waitingFirstPurchase" ? true : false}
                            >
                                Set Bonus
                            </ button >
                        }
                    </>
                )
            },
            sortable: false,
        },
    ];

    const configForTable = {
        page_size: 10,
        length_menu: [10, 20, 50],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        button: {
            csv: true,
        }
    }

    const usersSort = (column, records, order) => {
        let sortedRecords = [...records];

        if (column === "fiscal_code") {
            sortedRecords.sort((a, b) => {
                let valA = a[column] || "";
                let valB = b[column] || "";

                if (!valA && !valB) return 0;
                if (!valA) return order === "asc" ? 1 : -1;
                if (!valB) return order === "asc" ? -1 : 1;

                return order === "asc" ?
                    valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' }) :
                    valB.localeCompare(valA, undefined, { numeric: true, sensitivity: 'base' });
            });
        } else if (column === "kyc") {
            sortedRecords.sort((a, b) => {
                let hasDocA = a.id_document_front && a.id_document_back ? 1 : 0;
                let hasDocB = b.id_document_front && b.id_document_back ? 1 : 0;

                if (hasDocA === hasDocB) return 0;
                if (order === "asc") {
                    return hasDocB - hasDocA;
                } else {
                    return hasDocA - hasDocB;
                }
            });
        }

        return sortedRecords;
    };

    async function handleLiquidityModal(item) {
        getUsersLiquidity({ user_id: item.referrer_id }).then((res) => {
            if (res.success) {
                setItem({ 
                    ...item, 
                    liquidity_amount: res.liquidity_amount, 
                    withdrawable_liquidity_amount: res.withdrawable_liquidity_amount ,
                    primary_bonus_liquidity_amount: res.primary_bonus_liquidity_amount,
                    secondary_bonus_liquidity_amount: res.secondary_bonus_liquidity_amount,
                });
                setShowModal(true);
            } else {
                setItem(item);
                throw new Error("Something went while fetching liquidity");
            }
        }).catch((err) => {
            console.log(err);
            setError(true);
            setShowModal(true);
        });
    }

    async function handleLiquiditySubmit(e) {
        e.preventDefault();

        if (lqiAmt === 0 || lqiAmt === null || lqiAmt === undefined || lqiAmt === '' || lqiAmt < 1) {
            setvalidatioError({ ...validatioError, lqiAmt: true });
            return;
        }

        setvalidatioError({});
        setsaving(true);
        setUsersLiquidity({ user_id: item.referrer_id, bonus_liquidity: lqiAmt }).then((res) => {
            if (res.success) {
                setReferralBonus({ id: item.id }).then((res) => {
                    if (res.success) {
                        Swal.fire("Success", res.msg, "success");
                    } else {
                        throw new Error(res.msg);
                    }
                }).catch((err) => {
                    console.log(err);
                    Swal.fire("Failed", "Something went wrong", "error");
                }
                );
            } else {
                Swal.fire("Failed", res.msg, "error");
            }
        }).catch((err) => {
            console.log(err);
            Swal.fire("Failed", "Something went wrong", "error");
        }).finally(() => {
            setTimeout(() => {
                setsaving(false);
                //close modal
                setShowModal(false);
                //refresh the page
                window.location.reload();
            }, 2000);
        });
    }

    return (

        <>
            <div className="wrapper">
                {/* <div id="loader"></div> */}
                <Toaster />
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="container-full">


                        <section className="content">
                            <div className="row">
                                <div className="col-lg-12 col-12">

                                    <div className="box">
                                        <div className="box-header with-border">
                                            <h4 className="box-title">Referrals </h4>

                                        </div>
                                        <div className="box-body">

                                            {!loader ?
                                                <ReactDatatable
                                                    config={configForTable}
                                                    records={usersList}
                                                    columns={columns}
                                                    onSort={usersSort}
                                                />
                                                :
                                                <>
                                                    <br />
                                                    <br />
                                                    <center><h4><i className='fa fa-spinner fa-spin'></i> &nbsp; Please wait</h4></center>
                                                    <br />
                                                    <br />
                                                </>
                                            }

                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/*Add Liquidity Modal */}
                            <div
                                className="modal fade"
                                id="liquidityModal"
                                tabIndex={-1}
                                aria-labelledby="liquidityModalLabel"
                                aria-hidden="true"
                                style={{ display: showModal ? 'block' : 'none' }}
                            >
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        {/* await items is not empty */}
                                        {Object.keys(item).length > 0 ? (
                                            <>
                                                <div className="modal-header" data-bs-theme="dark">
                                                    <h5 className="modal-title" id="liquidityModalLabel">
                                                        Liquidity for {item.from_user_name}
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="btn-close"
                                                        aria-label="Close"
                                                        data-bs-dismiss="modal"
                                                        onClick={() => setShowModal(false)}
                                                    />
                                                </div>
                                                {
                                                    error ? (
                                                        <div className="modal-body">
                                                            <div className="container">
                                                                <div className="mb-3">
                                                                    <p className="text-center">
                                                                        Something went wrong. Please try again later
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <form onSubmit={(e) => handleLiquiditySubmit(e)}>
                                                            <div className="modal-body">
                                                                <div className="container">

                                                                    <div className="mb-4">
                                                                        {/** Liquidity Details some p whit some data */}
                                                                        <p>
                                                                            <strong>Available Liquidity:</strong> {item.liquidity_amount}
                                                                        </p>
                                                                        <p>
                                                                            <strong>Withdrawable Liquidity:</strong> {item.withdrawable_liquidity_amount}
                                                                        </p>
                                                                        <p>
                                                                            <strong>Primary Bonus Liquidity:</strong> {item.primary_bonus_liquidity_amount}
                                                                        </p>
                                                                        <p>
                                                                            <strong>Secondary Bonus Liquidity:</strong> {item.secondary_bonus_liquidity_amount}
                                                                        </p>
                                                                    </div>

                                                                    <div className="mt-4 mb-3">
                                                                        <h5 className='text-center mt-5'>Add Liquidity</h5>
                                                                        <label
                                                                            htmlFor="exampleFormControlInput1"
                                                                            className="form-label"
                                                                        >
                                                                            Liquidity Amount
                                                                        </label>
                                                                        <input
                                                                            type="number"
                                                                            step=".01"
                                                                            className="form-control"
                                                                            id="exampleFormControlInput1"
                                                                            placeholder="Enter Liquidity Amount"
                                                                            onChange={(e) => setLqiAmt(e.target.value)}
                                                                            name="liquidity_amount"
                                                                        />
                                                                        {validatioError.lqiAmt && (
                                                                            <span className="validationErr mt-1">
                                                                                Liquidity amount error
                                                                            </span>
                                                                        )}
                                                                    </div>

                                                                    <div className="modal-footer mt-20">
                                                                        {!saving ? (
                                                                            <button type="submit" className="btn btn-primary">
                                                                                Add Liquidity
                                                                            </button>
                                                                        ) : (
                                                                            <button
                                                                                type="submit"
                                                                                className="btn btn-primary"
                                                                                disabled
                                                                            >
                                                                                Please wait{" "}
                                                                                <i className="fa fa-spinner fa-spin"></i>
                                                                            </button>
                                                                        )}


                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-secondary"
                                                                            data-bs-dismiss="modal"
                                                                        >
                                                                            Close
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    )
                                                }
                                            </>
                                        ) : (
                                            <>
                                                <div className="modal-header" data-bs-theme="dark">
                                                    <h5 className="modal-title" id="liquidityModalLabel">
                                                        Add Liquidity
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="btn-close"
                                                        aria-label="Close"
                                                        data-bs-dismiss="modal"
                                                        onClick={() => setShowModal(false)}
                                                    />
                                                </div>
                                                {/* on body fa-spin and close button */}
                                                <div className="modal-body">
                                                    <div className="container">
                                                        <div className="mb-3">
                                                            <p className="text-center">
                                                                Please select a user to add liquidity
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/*Add Liquidity Modal Ends */}

                        </section>
                        {/* /.content */}
                        {/* /.content */}
                    </div>
                </div>

                <Footer />
            </div>
        </>


    )

}
export default Referrals;