import React, { useEffect, useState, useMemo } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import config from "../coreFIles/config";
import { getItemListAction, getItemPaymentDetailsAction } from "../Action/action";
import moment from "moment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import Swal from "sweetalert2";
import { showPaymentDetailsPopup, showRebalanceDetailsPopup } from '../utils/paymentPopup';
import { Box, Button } from '@mui/material';
import { CSVLink } from 'react-csv';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const Pagamenti = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [csvLoading, setCsvLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const fetchData = async () => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {
      let filters = JSON.stringify(columnFilters ?? []);
      const response = await getItemListAction(
        pagination.pageIndex,
        pagination.pageSize,
        filters,
      );

      if (response.success) {
        setData(response.data.items);
        setRowCount(response.data.totalItems);
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  useEffect(() => {
    fetchData();
  }, [
    columnFilters,
    pagination.pageIndex,
    pagination.pageSize,
  ]);

  const handlePaymentClick = async (item) => {
    try {
      const response = await getItemPaymentDetailsAction(item.id);
      if (response.success) {
        const result = await showPaymentDetailsPopup(item.name, response.data.payments, item.id);
        if (result.success) {
          await fetchData();
        }
      } else {
        Swal.fire({
          title: "Errore",
          text: response.msg || "Errore nel caricamento dei dettagli del pagamento",
          icon: "error"
        });
      }
    } catch (error) {
      console.error("Error fetching payment details:", error);
      Swal.fire({
        title: "Errore",
        text: error.response?.data?.msg || "Errore nel caricamento dei dettagli del pagamento",
        icon: "error"
      });
    }
  };

  const handleRebalanceClick = async (item) => {
    try {
      const response = await getItemPaymentDetailsAction(item.id);
      if (response.success) {
        const result = await showRebalanceDetailsPopup(item.name, response.data.payments, item.id);
        if (result.success) {
          await fetchData();
        }
      } else {
        Swal.fire({
          title: "Errore",
          text: response.msg || "Errore nel caricamento dei dettagli del ribilanciamento",
          icon: "error"
        });
      }
    } catch (error) {
      console.error("Error fetching rebalance details:", error);
      Swal.fire({
        title: "Errore",
        text: error.response?.data?.msg || "Errore nel caricamento dei dettagli del ribilanciamento",
        icon: "error"
      });
    }
  };

  const handleCsvDownload = async () => {
    try {
      setCsvLoading(true);
      // Usa i filtri attivi dalla tabella
      let filters = JSON.stringify(columnFilters ?? []);
      
      // Chiamata API con gli stessi filtri ma senza paginazione
      const response = await getItemListAction(0, 999999, filters);
      
      if (response.success && response.data.items) {
        const csvData = [
          ['ID', 'Nome', 'Fornitore', 'Tipo Fornitore', 'Venduto', 'Consegnato', 'Magazzino', 
           'Pagato', 'Data Fattura', 'Data Inizio Vendita', 'Data Chiusura Vendita', 
           'Data Pagamento', 'Stati Pagamento', 'Prezzo di acquisto', 'Prezzo di vendita','Stato vendita'],
          ...response.data.items.map(item => [
            item.id,
            item.name,
            item.supplier?.name || '-',
            item.supplier?.type === 'company' ? 'Azienda' : 'Privato',
            item.pre_order_target === 1 ? 'Sì' : 'No',
            item.delivered ? 'Sì' : 'No',
            item.warehouse?.name || '-',
            item.paid ? 'Sì' : 'No',
            item.invoice_date ? moment(item.invoice_date).format('DD/MM/YYYY') : '-',
            item.first_transaction_date ? moment(item.first_transaction_date).format('DD/MM/YYYY') : '-',
            item.pre_order_target === 1 ? (item.last_transaction_date ? moment(item.last_transaction_date).format('DD/MM/YYYY') : '-') : '-',
            item.payment_date ? moment(item.payment_date).format('DD/MM/YYYY') : '-',
            item.payments?.map(p => {
              const type = (() => {
                switch(p.type) {
                  case 'BEFORE_TARGET':
                  case 'AFTER_TARGET':
                    return 'Pagamento fornitore';
                  case 'REVENUE':
                    return 'Pagamento REVENUE';
                  case 'COLLECTO_REBALANCE':
                    return 'Ribilanciamento';
                  default:
                    return p.type;
                }
              })();
              return `${type} - ${p.payment_status}`;
            }).join('- ') || '-',
            item.paid_amount ? `${item.paid_amount}€` : '-------',
            item.market_value ? `${item.market_value}€` : '-------',
            item.draft ? "Bozza" : item.pre_order_target === 0 ? "In vendita" : "Venduto"
          ])
        ];
        setCsvData(csvData);
      }
    } catch (error) {
      console.error("Error exporting data:", error);
      Swal.fire({
        title: "Errore",
        text: "Errore durante l'esportazione dei dati",
        icon: "error"
      });
    } finally {
      setCsvLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID item",
        size: 50,
        enableColumnFilter: true,
        enableSorting: false,
      },
      {
        accessorKey: "name",
        header: "Nome item",
        enableColumnFilter: true,
      },
      {
        accessorKey: "supplier",
        header: "Fornitore",
        accessorFn: (row) => row.supplier?.name || '-',
        enableColumnFilter: true,
        enableSorting: false,
      },
      {
        accessorKey: "supplier_type",
        header: "Tipo fornitore",
        accessorFn: (row) => row.supplier?.type === 'company' ? 'Azienda' : 'Privato',
        enableColumnFilter: true,
        filterFn: (row, id, filterValue) => {
          return row.original.supplier?.type === (filterValue === 'company');
        },
        filterVariant: 'select',
        filterSelectOptions: [
          { value: 'company', text: 'Azienda' },
          { value: 'private', text: 'Privato' }
        ],
        enableSorting: false,
      },
      {
        accessorKey: "sold",
        header: "Venduto",
        Cell: ({ row }) => (
          <span className={`badge ${row.original.pre_order_target === 1 ? "bg-success" : "bg-danger"}`}>
            {row.original.pre_order_target === 1 ? "Sì" : "No"}
          </span>
        ),
        enableColumnFilter: true,
        filterFn: (row, id, filterValue) => {
          return row.original.pre_order_target === (filterValue === 'true');
        },
        filterVariant: 'select',
        filterSelectOptions: [
          { value: true, text: 'Sì' },
          { value: false, text: 'No' }
        ],
        enableSorting: false,
      },
      {
        accessorKey: "draft",
        header: "Bozza",
        enableColumnFilter: true,
        filterFn: (row, id, filterValue) => {
          return row.original.draft === (filterValue === 'true');
        },
        filterVariant: 'select',
        filterSelectOptions: [
          { value: true, text: 'Bozza' },
          { value: false, text: 'Pubblico' }
        ],
      },
      {
        accessorKey: "delivered",
        header: "Consegnato",
        filterVariant: 'select',
        filterSelectOptions: [
          { value: true, text: 'Sì' },
          { value: false, text: 'No' }
        ],
        Cell: ({ row }) => (
          <span className={`badge ${row.original.delivered ? "bg-success" : "bg-danger"}`}>
            {row.original.delivered ? "Sì" : "No"}
          </span>
        ),
        enableColumnFilter: true,
        filterFn: (row, id, filterValue) => {
          return row.original.delivered === (filterValue === 'true');
        },
        enableSorting: false,
      },
      {
        accessorKey: "warehouse",
        header: "Magazzino",
        accessorFn: (row) => row.warehouse?.name || '-',
        enableColumnFilter: true,
        enableSorting: false,
      },
      {
        accessorKey: "paid",
        header: "Pagato",
        filterVariant: 'select',
        filterSelectOptions: [
          { value: true, text: 'Sì' },
          { value: false, text: 'No' }
        ],
        Cell: ({ row }) => (
          <span className={`badge ${row.original.paid ? "bg-success" : "bg-danger"}`}>
            {row.original.paid ? "Sì" : "No"}
          </span>
        ),
        enableColumnFilter: true,
        filterFn: (row, id, filterValue) => {
          return row.original.paid === (filterValue === 'true');
        }
      },
      {
        accessorKey: "paid_amount",
        header: "Prezzo di acquisto",
        enableColumnFilter: true,
        Cell: ({ row }) => row.original.paid_amount ? `${row.original.paid_amount}€` : '-------',
        size: 100, //medium column
      },
      {
        accessorKey: "market_value",
        header: "Prezzo di vendita",
        enableColumnFilter: true,
        Cell: ({ row }) => row.original.market_value ? `${row.original.market_value}€` : '-------',
        size: 100, //medium column
      },
      {
        accessorKey: "invoice_date",
        header: "Data fattura",
        Cell: ({ row }) => row.original.invoice_date ? moment(row.original.invoice_date).format('DD/MM/YYYY') : '-',
        enableColumnFilter: true,
        filterVariant: 'date',
        enableSorting: false,
      },
      {
        accessorKey: "first_transaction_date",
        header: "Data inizio vendita",
        Cell: ({ row }) => {
          // Ordina le transazioni per data e prendi la prima
          const firstTransaction = row.original.first_transaction_date;
          
          return firstTransaction 
            ? moment(firstTransaction).format('DD/MM/YYYY')
            : '-';
        },
        enableColumnFilter: true,
        filterVariant: 'date',
        enableSorting: false,
      },
      {
        accessorKey: "last_transaction_date",
        header: "Data chiusura vendita",
        Cell: ({ row }) => {
          // Mostra l'ultima transazione solo se pre_order_target è 1
          if (row.original.pre_order_target === 1) {
            const lastTransaction = row.original.last_transaction_date;
            
            return lastTransaction 
              ? moment(lastTransaction).format('DD/MM/YYYY')
              : '-';
          }
          return '-';
        },
        enableColumnFilter: true,
        filterVariant: 'date',
        enableSorting: false,
      },
      {
        accessorKey: "payment_date",
        header: "Data pagamento",
        Cell: ({ row }) => row.original.payment_date ? moment(row.original.payment_date).format('DD/MM/YYYY') : '-',
        enableColumnFilter: true,
        filterVariant: 'date',
        enableSorting: false,
      },
      {
        accessorKey: "payment_status",
        header: "Stato Pagamento",
        Cell: ({ row }) => {
          const payments = row.original.payments || [];
          
          const getPaymentTypeLabel = (type) => {
            switch(type) {
              case 'BEFORE_TARGET':
              case 'AFTER_TARGET':
                return 'Pagamento fornitore';
              case 'REVENUE':
                return 'Pagamento revenue';
              case 'COLLECTO_REBALANCE':
                return 'Ribilanciamento';
              default:
                return type;
            }
          };
          
          return payments.length > 0 ? (
            <div className="d-flex flex-column gap-2">
              {payments.map((payment, index) => (
                <span 
                  key={index}
                  className="badge"
                  style={{
                    backgroundColor: payment.payment_status === 'waiting_confirmation' ? '#ffc107' : 
                                   payment.payment_status === 'completed' ? '#198754' : 
                                   '#dc3545',
                    color: payment.payment_status === 'waiting_confirmation' ? '#000' : '#fff',
                    fontWeight: 700,
                  }}
                >
                  {getPaymentTypeLabel(payment.type)} - {payment.payment_status}
                </span>
              ))}
            </div>
          ) : '-';
        },
        enableColumnFilter: false,
        enableSorting: false,
        muiTableBodyCellProps: {
          align: 'center',
          sx: { minWidth: '200px' }
        }
      },
      {
        accessorKey: "action",
        header: "Azione",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100,
        Cell: ({ row }) => {
          const payments = row.original.payments || [];
          const hasBeforeTarget = payments.some(p => p.type === 'BEFORE_TARGET');
          const hasAfterTarget = payments.some(p => p.type === 'AFTER_TARGET');
          const hasRevenue = payments.some(p => p.type === 'REVENUE');
          return (
            <div className="d-flex flex-column gap-2 align-items-center">
           
              {/* Pulsante Paga: solo se non ci sono pagamenti */}
              {payments.length === 0 && !row.original.paid ? (
                <button 
                  className="btn btn-success btn-sm w-100"
                  onClick={() => handlePaymentClick(row.original)}
                  title="Effettua pagamento"
                >
                 Paga
                </button>
              ) : null}
              
              {/* Pulsante Rebalance: solo se ho BEFORE_TARGET senza altri pagamenti e pre_order_target è 1 */}
              {hasBeforeTarget && !hasAfterTarget && !hasRevenue && row.original.pre_order_target === 1 && (
                <button 
                  className="btn btn-warning btn-sm w-100"
                  onClick={() => handleRebalanceClick(row.original)}
                  title="Ribilancia pagamenti"
                >
                  Ribilancia
                </button>
              )}
              
              {/* Pulsante Modifica: sempre visibile */}
              <a 
                href={`${config.baseUrl}itemAdd/${row.original.id}`} 
                className="btn btn-primary btn-sm w-100"
                title="Dettaglio item"
              >
                Modifica
              </a>
            </div>
          );
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
    ],
    []
  );

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark",
        },
      },
      
    ),
    []
  );

  const renderTopToolbarCustomActions = () => (
    <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
      {csvLoading ? (
        <div className="text-center">
          <div className="spinner-border" role="status" aria-hidden="true"></div>
        </div>
      ) : csvData.length > 0 ? (
        <CSVLink data={csvData} filename={`pagamenti_${moment().format('YYYY-MM-DD')}.csv`} className="btn btn-primary">
          Scarica CSV
        </CSVLink>
      ) : (
        <Button
          color="primary"
          onClick={handleCsvDownload}
          variant="contained"
          style={{ backgroundColor: '#1976d2' }}
        >
          Carica CSV
        </Button>
      )}
    </Box>
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    enableRowNumbers: false,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableGlobalFilter: false,
    initialState: { showColumnFilters: true },
    enableExporting: true,
    enableTopToolbar: true,
    enableBottomToolbar: true,
    enableToolbarInternalActions: true,
    positionToolbarAlertBanner: 'bottom',
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid #ffffff1f",
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    renderTopToolbarCustomActions,
  });

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={tableTheme}>
          <div className="wrapper">
            <Header />
          <Sidebar />
          <div className="content-wrapper">
            <div className="container-full">
              <section className="content">
                <div className="row">
                  <div className="col-12">
                    <div className="box">
                      <div className="box-header with-border">
                        <h4 className="box-title">Lista Pagamenti</h4>
                      </div>
                      <div className="box-body">
                        <MaterialReactTable table={table} />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <Footer />
          </div>
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
};

export default Pagamenti; 