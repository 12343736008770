import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import config from "../coreFIles/config";
import ReactDatatable from "@ashvin27/react-datatable";
import { getWithdrawlistAction, updatewithdrawAction, rejectwithdrawAction, updateWithdrawal_requestAction, sendRevolutTransfer } from "../Action/action";
import Swal from "sweetalert2";
import { Toaster } from "react-hot-toast";

const Withdraw = () => {
  const [allwithdrawlist, setallwithdrawList] = useState({});
  useEffect(() => {
    getpartner();
    updateWithdrawal_request();
  }, []);

  const updateWithdrawal_request = async () => {
    await updateWithdrawal_requestAction();
  };

  const getpartner = async () => {
    let res = await getWithdrawlistAction();
    if (res) {
      setallwithdrawList(res.data);
      console.log("123", res.data);
    }
  };

  const updatebuyRequest = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Make sure withdrawal amount has been transferred  to provided details by user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Approve it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await updatewithdrawAction(id);
        if (res.success) {
          await Swal.fire("Approved!", res.msg, "success");
          window.location.href = `${config.baseUrl}withdraw`;
          getpartner();
          //   setTimeout(() => {}, 1000);
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  const sendRevolutBankTransfer = async (id) => {
    Swal.fire({
      title: "Are you sure ?",
      text: "Revolut bank transfer",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Approve it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let res = await sendRevolutTransfer({ id });
          if (res.success) {
            await Swal.fire("Approved!", res.msg, "success");
            //window.location.href = `${config.baseUrl}withdraw`;
            let list = allwithdrawlist;
            list = list.map((item) => {
              if (item.id === id) {
                item.revolut_status = "SUCCESS";
                item.status = 1;
              }
              return item;
            });
            setallwithdrawList(list);
            //   setTimeout(() => {}, 1000);
          } else {
            Swal.fire("Failed!", res.msg, "error");
          }
        } catch (error) {
          console.log(error.response.data.error);
          Swal.fire("Failed!", error.response.data.error, "error");
        }
      }
    });
  };

  const rejectbuyRequest = async (id) => {
    Swal.fire({
      title: "Are you sure you want to reject?",
      html: `<textarea id="txtid" placeHolder="Please provide reject reasone" className="form-control rejectbuyform" name= "reject_reason" rows="4" cols="50" maxlength="200"></textarea>`,
      text: "You want to reject this withdraw!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Reject it!",
      preConfirm: () => {
        const login = Swal.getPopup().querySelector("#txtid").value.trimStart();
        if (!login || login == "" || login == undefined || login == null || login == " ") {
          Swal.showValidationMessage(`Please provide reject reasone`);
        }
        return { reject_reason: login };
      },
    }).then(async (result) => {
      //     Swal.fire(`
      //     reject_reason: ${result.value.reject_reason}
      //   `)
      if (result.isConfirmed) {
        let res = await rejectwithdrawAction({
          id: id,
          reject_reason: result.value.reject_reason,
        });
        if (res.success) {
          Swal.fire("Withdraw request rejected!", res.msg, "success");
          getpartner();
          //   setTimeout(() => {
          //     window.location.href = `${config.baseUrl}withdraw`;
          //   }, 1000);
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  const handleCsvDownload = () => {
    const headers = ["id.", "Name", "Email", "Payment Method Details", "IBAN", "Gross Amount", "Net Amount", "Fees", "Date", "Status"];
    const csvData = [
      headers,
      ...allwithdrawlist.map((item) => {
        const grossAmount = parseFloat(item.amount);
        const fees = grossAmount * 0.02;
        const netAmount = grossAmount - fees;
        return [
          item.id,
          item.name,
          item.email,
          item.type,
          item.iban_paypal_upi_id,
          `"${grossAmount.toFixed(2).replace('.', ',')}"`,
          `"${netAmount.toFixed(2).replace('.', ',')}"`,
          `"${fees.toFixed(2).replace('.', ',')}"`,
          item.current_datetime,
          item.status === 0 ? "Pending" : item.status === 1 ? "Approved" : item.status === 2 ? "Rejected" : "Unknown",
        ];
      })
    ];
    const fileName = "withdraw_data.csv";
    const csvString = "data:text/csv;charset=utf-8," + csvData.map((row) => row.join(",")).join("\n");
    const encodedUri = encodeURI(csvString);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columns = [
    {
      key: "#",
      text: "Sno.",
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Name",
      cell: (item) => {
        return `${item.name}`;
      },
    },
    {
      key: "email",
      text: "Email ",
      cell: (item) => {
        return `${item.email == null ? item.mobile_no : item.email}`;
      },
    },

    {
      key: "Payment Method Details",
      text: "Payment Method Details",
      cell: (item) => {
        return (
          <>
            <b>Type : </b> {item.type}
            <br />
            {item.type === "BANK" ? (
              <>
                <b>IBAN no. : </b> {item.iban_paypal_upi_id}
                <br />
              </>
            ) : (
              ""
            )}
            {item.type === "PAYPAL" ? (
              <>
                <b>Paypal Id : </b> {item.iban_paypal_upi_id}
                <br />
              </>
            ) : (
              ""
            )}
            {item.type === "UPI" ? (
              <>
                <b>UPI Id : </b> {item.iban_paypal_upi_id}
                <br />
              </>
            ) : (
              ""
            )}
            <b>Account Holder : </b> {item.account_holder}
          </>
        );
      },
    },
    {
      key: "Amount",
      text: "Amount",
      cell: (item) => {
        return `€ ${item.amount}`;
      },
    },
    {
      key: "Date & time",
      text: "Date & time",
      cell: (item) => {
        return `${item.current_datetime.substring(0, 10)}`;
      },
    },
    {
      key: "action",
      text: "Action",
      cell: (item) => {
        return (
          <>
            {item.status === 0 ? (
              <>
                <button onClick={() => updatebuyRequest(item)} type="button" className="btn-sm  btn-primary m-2">
                  Approve
                </button>
                &emsp;
                <button onClick={() => rejectbuyRequest(item)} type="button" className="btn-sm  btn-primary m-2">
                  Reject
                </button>
                &emsp;
              </>
            ) : item.status === 1 ? (
              <>
                <span style={{ color: "green" }}>Approved</span>
              </>
            ) : item.status === 2 ? (
              <>
                <span style={{ color: "red" }}>Reject</span>
              </>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      cell: (item) => {
        return (
          <>
            {item.revolut_status === "PENDING" && item.type === "BANK" && item.status == 0 && (
              <>
                <button onClick={() => sendRevolutBankTransfer(item.id)} type="button" className="btn-sm  btn-primary m-2">
                  Send bank transfer
                </button>
              </>
            )}
          </>
        );
      },
    },
  ];

  const configForTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    pagination: "advance",
    button: {
      excel: false,
      print: false,
    },
  };

  return (
    <>
      <div className="wrapper">
        <Toaster />

        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Withdraw Request</h4>
                      <button className="btn btn-sm btn-primary" onClick={handleCsvDownload}>
                        Export CSV
                      </button>
                    </div>
                    <div className="box-body">
                      <ReactDatatable config={configForTable} records={allwithdrawlist} columns={columns} />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* /.content */}
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Withdraw;
